:root{

    /*      Theme colors        */
    --text-gray: #3f4954;
    --text-light : #686666da;
    --bg-color: #0f0f0f;
    --white: #ffffff;
    --midnight: #104f55;

    --brand-color:#08cce6;
  

    /* gradient color   */
    --background: linear-gradient(120deg, #78a0e1 0%, #56b75ec7 100%);

    /*      theme font-family   */
    --Abel: 'Abel', cursive;
    --Anton: 'Anton', cursive;
    --Josefin : 'Josefin', cursive;
    --Lexend: 'Lexend', cursive;
    --Livvic : 'Livvic', cursive;
}
