/* grid layout design  */

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "side body body body body body";
  position: relative;
  margin-top: 80px;
  transition: all 1s ease-in-out;
   
}
/* .gridResponsive{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "side side body body body body";
   position: relative;
   margin-top: 80px;
   transition: all 1s ease-in-out;
 
} */

.active{

  color: red !important;
}

.sideNavigationContainer {
  margin-top: 30px;
}
.main {
  padding: 20px 50px;
}
.sidebar {
  grid-area: side;
  
  padding: 20px;
  background-color: white;
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  left: 0;
  width: 250px;
  overflow-y: scroll;
  scrollbar-width:none;
  z-index: 1;
  padding-bottom: 99px;
}
.sidebar::-webkit-scrollbar {
  width: 2px;
  background: var(--brand-color);
}

.mainBody {
  position: absolute;
  grid-area: body;
  background: white;
  min-height: 70vh;
  
  padding: 20px;
}
.openbtn {
  display: none;
  
}
.closeIcon {
  display: none;
}
.closeIcon svg {
  font-size: 30px;
  cursor: pointer;
  fill: red;
}

/* button */

.ebookSidebarButton {
  width: 100%;
  font-size: 14px;
  font-weight:700;
  color: black;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding: 5px;
}
.activeButton{
   color:var(--brand-color) !important;

}
.activeButton::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 1px;
  top: 35px;
  left: -11px;
  z-index: 2222;
  background-color: var(--brand-color);
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  color: var(--brand-color);
}
.activeButton::before {
  content: "";
  position: absolute;
  width: 11px;
  height: 35px;
  top: -1px;
  left: -40px;
  background-color: var(--brand-color);
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transition: width 0.5s ease-in-out;
  color: var(--brand-color);
}


.bookName{
  font-family: 'Anton', sans-serif;
    letter-spacing: 1px;
    color: black;
}

@media screen and (max-width: 1500px) {
  .grid {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "body body body body body body";
    position: relative;
    margin-top: 80px;
    margin-left: 0px;
  }
  .sidebar {
    width: 0px;
    left: -56px;
    transition: width 0.5s ease-in-out;
  }
  .openbtn {
    display: block;
  }
  .toggle {
    width: 250px;
    transition: width 0.5s ease-in-out;
    left: 0;
  }
  .closeIcon {
    display: block;
    right: -185px;
    position: relative;
  }
  .main {
    padding: 10px;
  }
  .mainBody {
    position:initial;
 
  }
}


/* end point style */

.hrEnd {
  margin-top: 10px;
  overflow: visible; 
  padding: 20px;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}
.hrEnd:after {
  content: "The End";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: white;
}
.bookContainer img{
   max-width: 100% !important;
  


}