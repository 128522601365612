@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Taviraj&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;

  
 
}
ul li{
  list-style-type:none;
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.navbar {

  margin-bottom: 0px !important;
 
}
.navbar-dark .navbar-toggler {
   color:none;
   border-color: transparent !important;
}
.navbar .navbar-toggler {
  width: 30px; 
  height: 30px;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  outline:none;
  border:none;
  
}


.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  /* top: -5px; */
  margin-top: -20px;
  margin-left: -20px;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: none !important;
 box-shadow: none !important;
}

