.demo-editor {
    height: auto !important;
    padding: 5px !important;
    border-radius: 2px !important;
    background-color: white;
    border: 1px solid #d9d9d9;
  }

  /* table start */


.table-heading-data th{

   font-size:18px !important;
   font-weight: 900 !important;

     
  }

  .unused-images-body img{

      width: 100px;
      height:30px;
  }