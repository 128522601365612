.admin-project-images-container img{

   
    height: 100px;
}

.editIcon svg{
    fill:blue;
    font-size: 20px;
    cursor: pointer;
}
.admin-projectImage{

  width: 200px;
  
}