/* toggler iconm style */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
.logoHeading{

    font-family: 'Cabin', sans-serif;
    letter-spacing: .5px;
    color: black;
    font-size: 20px;
 
   
}
.title{
    font-family: 'Caveat', cursive;
    line-height: 0.5px;
    font-size: 16px ;
    color: rgb(0, 0, 0);
    text-transform: capitalize;

}
.show_icon svg, .blog_icon{
    width: 30px;
    height: 30px;
  margin-left:-20px;
  fill: var(--brand-color);

}

.blog_icon svg{


}