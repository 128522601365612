#contract {
  background: var(--background);
}

/* contract information  */

.button {
  display: inline-block;
  letter-spacing: 0.8px;
  background: var(--brand-color);
  border-radius: 5px;
  height: 48px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: "Fjalla One", sans-serif;
}

.button a {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  color: #ffffff;
  padding-left: 35px;
  padding-right: 35px;
}

.button:hover {
  opacity: 0.8;
}

.contact_info {
  width: 100%;
  padding-top: 70px;
}

.contact_info_item {
  width: calc((100% - 60px) / 3);
  height: 100px;
  border: solid 1px #e8e8e8;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  padding-left: 32px;
  padding-right: 15px;
}

.contact_info_image {
  width: 45px;
  height: 45px;
  text-align: center;
}

.contact_info_image i {
  max-width: 100%;
}
.touch-style{

   color:var(--brand-color);
   font-size: 35px;
   font-weight: 700;
   margin-bottom: 37px;
   letter-spacing: 0.5px;
   font-family: "Fjalla One", sans-serif;
}
.contact_info_content {
  padding-left: 17px;
}

.contact_info_title {
  font-weight: 500;
  font-family: "Fjalla One", sans-serif;

}

.contact_info_text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

/* contract form  */

.contact_form {
  padding-top: 85px;
}

.contact_form_container {
}

.contact_form_title {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 37px;

  letter-spacing: 0.5px;

  font-family: "Fjalla One", sans-serif;
}

.contact_form_inputs {
  margin-bottom: 30px;
}

.input_field {
  width: calc((100% - 60px) / 3);
  height: 50px;
  padding-left: 25px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  outline: none;
  color: #0e8ce4;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.input_field:focus,
.text_field:focus {
  border-color: #b2b2b2;
}

.input_field:hover,
.text_field:hover {
  border-color: #b2b2b2;
}

.input_field::-webkit-input-placeholder,
.text_field::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field:-moz-placeholder,
.text_field:-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field::-moz-placeholder,
.text_field::-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field:-ms-input-placeholder,
.text_field:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field::input-placeholder,
.text_field::input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.text_field {
  width: 100%;
  height: 160px;
  padding-left: 25px;
  padding-top: 15px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  color: #0e8ce4;
  outline: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.contact_submit_button {
  padding-left: 35px;
  padding-right: 35px;
  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 24px;
}

.panel {
  width: 100%;
  height: 50px;
  background: #fafafa;
  margin-top: 120px;
}

@media only screen and (max-width: 991px) {
  .contact_info_item {
    width: 100%;
    margin-bottom: 30px;
  }

  .contact_info_item:last-child {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .input_field {
    margin-bottom: 30px;
    width: 100%;
  }

  .input_field:last-child {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .contact_submit_button {
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
