
.img-container {
    max-width:300px;
   
}

.img-container img{
    margin-left:5px;
    width:70%;
    cursor:zoom-in;
    border: 4px solid white;
   
}
.pswp__bg {
    background-color: #fff !important;
}
.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
    background-color: rgb(255 255 255 / 30%) !important;
}
.pswp__button--fs{

background-color:gray !important;
}
.pswp__button--close{
    background-color:gray !important;

}