.biography {
  margin-top: 40px;
}

.biography h2{
  color: #393939;
  font-size: 23px;
  letter-spacing: 3px;
  font-family:DroidSerif-Regular; 
  text-transform: uppercase;
}

.biography .contents-bio{
   color: #5E5A57;
  text-align: justify;
  font-size: 12px;
  font-family:SourceSansPro-Regular;
}

.biography address{
    font-family:SourceSansPro-Regular;
     font-size: 16px;
     display: block; 
   line-height: 0;
}

.biography h3{
    font-family:DroidSerif-Regular;
   color: #093145;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  padding-top: 10px;  
}

.biography table tr{
  line-height: 20px;
}

.biography table tr td{
    font-family:DroidSerif-Regular;
  font-size: 12px;  
  color: #5E5A57;
}

.biography table tr td:nth-child(1){
     font-family:DroidSerif-Regular;
     color: #2a8446;
    font-size: 11px; 
    vertical-align: top;
   width:65px;
}

.biography table tr td:nth-child(2){
     font-family:SourceSansPro-Regular;
     padding: 0px 10px;
  vertical-align: top;
}

.biography table tr td:nth-child(3){
     font-family:SourceSansPro-Regular;
     padding: 0px 10px;
  font-size: 12px;  
}

.bioInformationDetails{
   font-size: 16px;
}

.aboutDetails{

   
}

.aboutDetailsCard{
   border-bottom: none !important;
   background-color:#fff !important
}
.birth-date{
  font-size: 12px;
  color: #8c8c8c;
  /* margin-top: 45px; */
  position: relative;
  top: -14px;
}