 
 @import url('https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap');

.product-container {
  /* background-color: #e9e4e4dc; */


  min-height:80vh;
 
}
.project-title {
  letter-spacing: 0.8px;
  color: rgb(0, 0, 0);
  font-family: "Taviraj", serif;
  font-weight: 800;
}


.projectDescription p{
  color: #433f3f;
  font-family: "Roboto", Sans-serif !important;
  font-weight: 400;


}

.print-img-container img {
  width: 200px;
}
.comming-soon-text {
  padding-bottom: 50px;
}

.comingSoon-img,
.onGoing-img {
  width: 300px;
  height: 250px;
}
.onGoing-img {
}
.ResponsiveMasonry {
  margin-left: 1.8%;

  width: 82% !important;
}
.my-masonry-grid{
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
  column-width: 20%;
}

@media (max-width: 768px) {
  .ResponsiveMasonry {
    margin-left: 24%;
  }
}
@media (max-width: 450px) {
  .ResponsiveMasonry {
    margin-left: 15%;
  }
}
/* @media (max-width: 1600px){
  .ResponsiveMasonry{
      margin-left:  -200px ;

  }
  


} */

/* .my-masonry-grid_column {
  padding-left: 30px; 
  background-clip: padding-box;
}

/* Style your items 
.my-masonry-grid_column > div change div to reference your elements you put in <Masonry> 
  background: grey;
  margin-bottom: 30px;
} 

*/


/* title design  */

@keyframes sway {
  0% {
      transform: rotate(0);
      animation-timing-function: ease-in-out;
  }
  10% {
      transform: rotate(-12deg);
      animation-timing-function: ease-in-out;
  }
  30% {
      transform: rotate(8deg);
      animation-timing-function: ease-in-out;
  }
  45% {
      transform: rotate(-4deg);
      animation-timing-function: ease-in-out;
  }
  60% {
      transform: rotate(2deg);
      animation-timing-function: ease-in-out;
  }
  70% {
      transform: rotate(-.1deg);
      animation-timing-function: ease-in-out;
  }
  75% {
      transform: rotate(.05deg);
      animation-timing-function: ease-in-out;
  }
  80% {
      transform: rotate(0);
      animation-timing-function: ease-in-out;
  }
  100% {
      transform: rotate(0);
  }
}


.example {
  position: relative;
  display: inline-block;
  font-family: 'Spicy Rice', cursive;
  line-height: 1;
  font-size: 40px;
  padding: 32px 32px;
 
  animation: sway 3s linear infinite;
  transform-origin: 50% -32px;
 
  }
  @media (max-width:1000px){

    .print-img-container .project-title {
      letter-spacing: 0.8px;
      color: rgb(0, 0, 0);
      font-family: "Taviraj", serif;
      font-weight: 600;
    }
    .example {
      position: relative;
      display: inline-block;
      font-family: 'Spicy Rice', cursive;
      line-height: 1;
      font-size: 29px;
      padding: 32px 32px;
     
      animation: sway 3s linear infinite;
      transform-origin: 50% -32px;
     
      }
  
     
  }

