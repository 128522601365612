.imageContainer{

    max-width: 200px;
    max-height: 200px;
}

.imageContainer img{

     width: 100%;
     max-height: 150px;
}