.seriesFolderEditeIcon{

     color: blue;
}


/* series folder list table */
.folderName{
 cursor: pointer;

 text-decoration: underline;

}